import {defineMessages} from "react-intl";
import {
  CHINESE_SIMPLIFIED,
  CHINESE_TRADITIONAL,
  DUTCH,
  ENGLISH,
  FRENCH,
  GERMAN,
  HINDI,
  ITALIAN,
  JAPANESE,
  POLISH,
  PORTUGUESE,
  RUSSIAN,
  SPANISH,
  HEBREW,
  TURKISH
} from './permissions';
import {FEATURES} from './features';
import {featureToggle} from '../components/Common/FeatureToggle/FeatureToggleUtils/FeatureToggleUtils';

export const LOCALES = {
  EN: 'en',
  FR: 'fr',
  ES: 'es',
  DE: 'de',
  IT: 'it',
  JA: 'ja',
  PT: 'pt',
  ZH_HANS: 'zh-hans',
  ZH_HANT: 'zh-hant',
  NL: 'nl',
  PL: 'pl',
  HI: 'hi',
  RU: 'ru',
  HE: 'he',
  ...(featureToggle(FEATURES.MAPI_272) ? {TR: 'tr'} : {})
};

export const RTL_LOCALES = [ LOCALES.HE ];

export const localeOptions = [
  {
    value: LOCALES.EN,
    label: 'English',
    multiLingualLabel: 'English - default',
    permission: ENGLISH
  }, {
    value: LOCALES.FR,
    label: 'Français',
    multiLingualLabel: 'Français - French',
    permission: FRENCH
  }, {
    value: LOCALES.ES,
    label: 'Español',
    multiLingualLabel: 'Español - Spanish',
    permission: SPANISH
  }, {
    value: LOCALES.DE,
    label: 'Deutsch',
    multiLingualLabel: 'Deutsch - German',
    permission: GERMAN
  }, {
    value: LOCALES.IT,
    label: 'Italiano',
    multiLingualLabel: 'Italiano - Italian',
    permission: ITALIAN
  }, {
    value: LOCALES.JA,
    label: '日本語',
    multiLingualLabel: '日本語 - Japanese',
    permission: JAPANESE
  }, {
    value: LOCALES.PT,
    label: 'Português',
    multiLingualLabel: 'Português - Portuguese',
    permission: PORTUGUESE
  }, {
    value: LOCALES.ZH_HANS,
    label: '简体中文',
    multiLingualLabel: '简体中文 - Chinese Simplified',
    permission: CHINESE_SIMPLIFIED
  }, {
    value: LOCALES.ZH_HANT,
    label: '繁體中文',
    multiLingualLabel: '繁體中文 - Chinese Traditional',
    permission: CHINESE_TRADITIONAL
  }, {
    value: LOCALES.NL,
    label: 'Nederlands',
    multiLingualLabel: 'Nederlands - Dutch',
    permission: DUTCH
  }, {
    value: LOCALES.PL,
    label: 'Polski',
    multiLingualLabel: 'Polski - Polish',
    permission: POLISH
  }, {
    value: LOCALES.HI,
    label: 'हिंदी',
    multiLingualLabel: 'हिंदी - Hindi',
    permission: HINDI
  }, {
    value: LOCALES.RU,
    label: 'Русский',
    multiLingualLabel: 'Русский - Russian',
    permission: RUSSIAN
  }, {
    value: LOCALES.HE,
    label: 'עִברִית',
    multiLingualLabel: 'עִברִית - Hebrew',
    permission: HEBREW
  },
  ...(featureToggle(FEATURES.MAPI_272) ? [{
    value: LOCALES.TR,
    label: 'Türkçe',
    multiLingualLabel: 'Türkçe - Turkish',
    permission: TURKISH
  }]: [])
];

export const messageLocales = defineMessages({
  en: {
    id: 'locale.en',
    defaultMessage: 'English'
  },
  fr: {
    id: 'locale.fr',
    defaultMessage: 'French'
  },
  es: {
    id: 'locale.es',
    defaultMessage: 'Spanish'
  },
  de: {
    id: 'locale.de',
    defaultMessage: 'German'
  },
  it: {
    id: 'locale.it',
    defaultMessage: 'Italian'
  },
  ja: {
    id: 'locale.ja',
    defaultMessage: 'Japanese'
  },
  pt: {
    id: 'locale.pt',
    defaultMessage: 'Portuguese'
  },
  "zh-hans": {
    id: 'locale.zh_hans',
    defaultMessage: 'Chinese Simplified'
  },
  "zh-hant": {
    id: 'locale.zh_hant',
    defaultMessage: 'Chinese Traditional'
  },
  nl: {
    id: 'locale.nl',
    defaultMessage: 'Dutch'
  },
  pl: {
    id: 'locale.pl',
    defaultMessage: 'Polish'
  },
  hi: {
    id: 'locale.hi',
    defaultMessage: 'Hindi'
  },
  ru: {
    id: 'locale.ru',
    defaultMessage: 'Russian'
  },
  he: {
    id: 'locale.he',
    defaultMessage: 'Hebrew'
  },
  tr: {
    id: 'locale.tr',
    defaultMessage: 'Turkish'
  }
});
