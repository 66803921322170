import waRequest from './wa-request';

export const apiBase = '/checkin-notifications/v1';

/**
 * Initiate authenticated GET ${apiBase}/organization/{organizationId}/configurations/{configurationId}
 *
 * @returns {Promise}
 */
function getCheckInNotifications(organizationId) {
  const url = `${apiBase}/organizations/${organizationId}/configurations`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}


/**
 * Initiate authenticated GET ${apiBase}/organization/{organizationId}/configurations/{configurationId}
 *
 * @returns {Promise}
 */
function getCheckInNotificationItem(organizationId, configurationId) {
  const url = `${apiBase}/organizations/${organizationId}/configurations/${configurationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/**
 * Initiate authenticated GET `${apiBase}/organizations/${organizationId}/confirmation-email-configuration`
 *
 * @returns {Promise}
 */
function getConfirmationEmailConfiguration({pathParams: {organizationId}}) {
  const url = `${apiBase}/organizations/${organizationId}/confirmation-email-configuration`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
  });
}

/**
 * Initiate authenticated PUT `${apiBase}/organizations/${organizationId}/confirmation-email-configuration`
 *
 * @returns {Promise}
 */
function updateConfirmationEmailConfiguration({pathParams: {organizationId}, data}) {
  const url = `${apiBase}/organizations/${organizationId}/confirmation-email-configuration`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: data
  });
}

/**
 * Initiate authenticated DELETE `${apiBase}/organizations/${organizationId}/confirmation-email-configuration`
 *
 * @returns {Promise}
 */
function deleteConfirmationEmailConfiguration({pathParams: {organizationId}}) {
  const url = `${apiBase}/organizations/${organizationId}/confirmation-email-configuration`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

/**
 * Initiate authenticated POST ${apiBase}/organization/{organizationId}/configurations
 *
 * @returns {Promise}
 */
function addCheckInNotification(organizationId, {name, checkinType, organizations, recipients, language, includeSubOrganizations, notifyGoc}) {
  const url = `${apiBase}/organizations/${organizationId}/configurations`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: {
      name,
      checkinType,
      organizations,
      recipients,
      language,
      includeSubOrganizations,
      notifyGoc
    }
  });
}

/**
 * Initiate authenticated PUT ${apiBase}/organization/{organizationId}/configurations/{configurationId}
 *
 * @returns {Promise}
 */
function updateCheckInNotification(organizationId, configurationId, {name, checkinType, organizations, recipients, language, includeSubOrganizations, notifyGoc}) {
  const url = `${apiBase}/organizations/${organizationId}/configurations/${configurationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: {
      name,
      checkinType,
      organizations,
      recipients,
      language,
      includeSubOrganizations,
      notifyGoc
    }
  });
}

/**
 * Initiate authenticated DELETE ${apiBase}/organization/{organizationId}/configurations/{configurationId}
 *
 * @returns {Promise}
 */
function deleteCheckInNotification(organizationId, configurationId) {
  const url = `${apiBase}/organizations/${organizationId}/configurations/${configurationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

export default {
  getCheckInNotifications,
  getCheckInNotificationItem,
  getConfirmationEmailConfiguration,
  updateConfirmationEmailConfiguration,
  deleteConfirmationEmailConfiguration,
  addCheckInNotification,
  updateCheckInNotification,
  deleteCheckInNotification,
};
