import mapboxgl from 'mapbox-gl';

import MapLayer from '../../MapLayer';

import signalSvg from '../../../../../../assets/crisis-signal.svg';

export const CRISIS_SIGNAL_SOURCE_ID = 'crisis-signal-source';
export const CRISIS_SIGNAL_INTERNAL_LAYER_ID = 'crisis-signal-internal-layer';
export const CRISIS_SIGNAL_LAYER_ID = 'crisis-signal-layer';

function createImage() {
  let img = new Image(20,20);
  img.src = signalSvg;
  img.style['zIndex'] = '700';
  img.style['cursor'] = 'pointer';
  return img;
}

/**
 * Creates a data source for the /intel/v3/alerts/searches
 * results and one layer for all the alert types; info, caution, warning, and critical types.
 *
 */
export default class CrisisSignalLayer extends MapLayer {
  constructor() {
    super();
    this.pointData = false;
    this.polygonData = false;
    // objects for caching and keeping track of HTML marker objects (for performance)
    this.markers = {};
    this.markersOnScreen = {};
  }
  updateMarkers(map) {
    const newMarkers = {};
    const features = map.querySourceFeatures(CRISIS_SIGNAL_SOURCE_ID);
    const self = this;
    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (let i = 0; i < features.length; i++) {
      features[i].geometry = features[i]._geometry;
      const coords = features[i].geometry.coordinates;
      const props = features[i].properties;
      const tile = features[i].tile || {};
      const id = props?.locId + tile.x + tile.y + tile.z;
      let marker = self.markers[id];
      if (!marker) {
        const el = createImage(props);
        marker = new mapboxgl.Marker({
          element: el
        }).setLngLat(coords);
        self.markers[id] = marker;
      }
      newMarkers[id] = marker;
      if (!self.markersOnScreen[id]) {
        marker.addTo(map);
      }
    }
    // for every marker we've added previously, remove those that are no longer visible
    for (let id in self.markersOnScreen) {
      if (!newMarkers[id]) {
        self.markersOnScreen[id].remove();
      }
    }
    self.markersOnScreen = newMarkers;
  }
  getLayerIds() {
    return [
      CRISIS_SIGNAL_INTERNAL_LAYER_ID, CRISIS_SIGNAL_LAYER_ID
    ];
  }
  // intentionally omit polygon layers
  getInteractiveLayerIds() {
    return [CRISIS_SIGNAL_INTERNAL_LAYER_ID, CRISIS_SIGNAL_LAYER_ID];
  }
  createSources() {
    if (this.map) {
      this.map.addSource(CRISIS_SIGNAL_SOURCE_ID, {
        type: 'geojson',
        data: this.pointData
      });
    }
  }
  createLayers() {
    if (this.map) {
      this.map.addLayer({
        id: CRISIS_SIGNAL_INTERNAL_LAYER_ID,
        source: CRISIS_SIGNAL_SOURCE_ID,
        type: 'circle',
        filter: ['==', ['get', 'cluster'], true],
        'paint': {
          'circle-color': 'rgba(0,0,0,0)',
          'circle-radius': [
            'step',
            ['get', 'totalCount'],
            20, 10,
            25, 20,
            30
          ]
        }
      });

      this.map.addLayer({
        id: CRISIS_SIGNAL_LAYER_ID,
        source: CRISIS_SIGNAL_SOURCE_ID,
        type: 'circle',
        paint: {
          'circle-radius': 8,
          'circle-color': 'rgba(0,0,0,0)'
        }
      });
    }
  }
  clearMarkers() {
    Object.values(this.markersOnScreen).forEach(marker => marker.remove());
    this.markersOnScreen = {};
    this.markers = {};
  }
  /**
   * Set the source data for all point layers.
   *
   * @param pointData
   */
  setPointData(pointData) {
    if (this.pointData !== pointData) {
      // Save data reference for comparison and use onAdd
      this.pointData = pointData || false;
      if (this.map) {
        this.clearMarkers();
        this.map.getSource(CRISIS_SIGNAL_SOURCE_ID)?.setData(this.pointData);
      }
    }
  }
  onAdd(map) {
    super.onAdd(map);
    // after the GeoJSON data is loaded, update markers on the screen on every frame
    const self = this;
    map.on('render', function() {
      if (!map.isSourceLoaded(CRISIS_SIGNAL_SOURCE_ID)) {
        return;
      }
      self.updateMarkers(map);
    });
  }
}