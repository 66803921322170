import {Component} from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';

import {ConnectedRouter as Router} from 'connected-react-router';
import {CookiesProvider} from 'react-cookie';

import history from '../../../services/history';
import createStore from '../../../redux/store';

import LocalizationProvider from '../../../containers/Localization/LocalizationProvider';

const store = createStore();

export default class ContextProvider extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired
  };

  render() {
    return (
      <CookiesProvider>
        <Provider store={store}>
          <LocalizationProvider>
            <Router history={history}>
              {this.props.children}
            </Router>
          </LocalizationProvider>
        </Provider>
      </CookiesProvider>
    );
  }
}
