import {PERSON_EXPORT_DATA_FIELD_NAMES} from '../../../constants/persons';

export const REPORT_FORM_FIELDS = {
  NAMES: 'names',
  INCLUDE_ORGS: 'includeOrganizations',
  INCLUDE_PERSON_TYPES: 'includePersonTypes',
  ACCOUNT_INFO: 'accountInfo',
  EMAILS: 'emails',
  PHONES: 'phones',
  EMERGENCY_CONTACT: 'emergencyContacts',
  IDENTIFIERS: 'identifiers',
  OPTIONAL_FIELDS: 'optionalFields',
  ADDRESSES: 'addresses',
  MOBILE_LOCATIONS: 'mobileLocations',
  TRIP_SEGMENTS: 'tripSegments',
  EXPATRIATIONS_INCLUDED: PERSON_EXPORT_DATA_FIELD_NAMES.EXPATRIATIONS_INCLUDED,
  STOPOVERS_INCLUDED: PERSON_EXPORT_DATA_FIELD_NAMES.STOPOVERS_INCLUDED
};