import {REPORT_FORM_FIELDS} from '../../../components/Persons/ExportDetailsModal/ExportDetailsForm.utils';

export function processForApi(values = {}) {

  //Set specific data options based on corresponding 'included' property
  //Do not add 'included' properties to returned object
  return Object.entries(values).reduce((previousValue, [key, value]) => {
    switch (key) {
      case REPORT_FORM_FIELDS.ACCOUNT_INFO:
      case REPORT_FORM_FIELDS.ADDRESSES:
      case REPORT_FORM_FIELDS.EMAILS:
      case REPORT_FORM_FIELDS.PHONES:
      case REPORT_FORM_FIELDS.EMERGENCY_CONTACT:
      case REPORT_FORM_FIELDS.IDENTIFIERS:
      case REPORT_FORM_FIELDS.NAMES:
      case REPORT_FORM_FIELDS.MOBILE_LOCATIONS:
      case REPORT_FORM_FIELDS.TRIP_SEGMENTS:
        return {
          ...previousValue,
          [key]: values[[key] + 'Included'] ? value : undefined
        };
      case REPORT_FORM_FIELDS.INCLUDE_ORGS:
      case REPORT_FORM_FIELDS.INCLUDE_PERSON_TYPES:
      case REPORT_FORM_FIELDS.OPTIONAL_FIELDS:
      case REPORT_FORM_FIELDS.EXPATRIATIONS_INCLUDED:
      case REPORT_FORM_FIELDS.STOPOVERS_INCLUDED:
        return {...previousValue, [key]: value};
      default:
        return {...previousValue};
    }
  }, {});
}