import waRequest from './wa-request';

const cancelableSingletonPostAirportSearch = waRequest.createCancelableSingleton();
const cancelableRailwayListSingleton = waRequest.createCancelableSingleton();

export const apiBase = '/geo-locations/v2';

/**
 * Initiate authenticated GET ${apiBase}/navtree
 *
 * Hardcoded for namespace and relationtype of "internal"
 * @returns {Promise}
 */
function getLocations(locale) {
  const url = `${apiBase}/navtree`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: {
      namespaces: ['internal'],
      relationtype: 'internal'
    },
    params: {
      locale
    }
  });
}

/**
 * Initiate authenticated GET ${apiBase}/locationdetails
 *
 * Hardcoded relationtype of "internal"
 * @returns {Promise}
 */
function getLocationDetails(locationId) {
  const url = `${apiBase}/locationdetails`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: {
      relationtypes:["internal"],
      ids: [{"internal": locationId}],
      resolution: "low"
    }
  });
}

/**
 * Initiate authenticated GET ${apiBase}/locationsbyname
 *
 * @param configData The search term to use for location name match. Accepts % wildcard.
 * @param configData.data valid POST body elements
 * @param configData.signal an abort controller signal for canceling the request
 *
 * Hardcoded namespace of "internal"
 * @returns {Promise}
 */
function getLocationsByNameConfigPattern(configData) {

  const {
    data,
    signal
  } = configData;

  const url = `${apiBase}/locationsbyname`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data,
    signal
  });
}

const DEFAULT_AIRPORT_CODE_SORT = 'relevance,desc';

function postAirportNameAndIataSearch(value, locale = 'en', page = 0, size = 25, sort = DEFAULT_AIRPORT_CODE_SORT) {

  const url = `${apiBase}/airports/searches`;

  return cancelableSingletonPostAirportSearch.requestAuthenticated({
    url,
    method: 'post',
    params: {
      locale,
      page,
      size,
      sort
    },
    data: {
      nameSearch: value,
      iataSearch: value,
    }
  })
}

/**
 * Performs a search for airport data based on a filter.
 * Making a request to the /airports/searches service endpoint
 * The default sort is relevance, descending. The most relevant result will be first
 *
 * @param {Object} config configuration object
 * @param {Object} config.params query parameters
 * @param {string} [config.params.locale = 'en'] The requested language to return the airport names in ISO 639-1 format. Can be a comma seperated list for specifying multiple locales (eg. "en,fr").
 * @param {number} [config.params.page = 0] Zero-based page index (0..N)
 * @param {number} [config.params.size = 100] The size of the page to be returned
 * @param {string} config.params.sort Example: 'name,asc' The sort order for the request. Sortable attributes include: name, internalParentName, internalParentId, iata, iso2_country, bookingType, relevance. Requests to sort other attributes will be rejected. relevance sorting will only work when both iataSearch and nameSearch params are present. 'relevance,desc' is the default sort
 * @param {Object} config.data request body
 * @param {string} config.data.name Airport name to filter by
 * @param {Array} config.data.bookingTypes A list of booking types to filter by
 * @param {number} config.data.internalParentId Internal Parent ID to filter by
 * @param {string} config.data.internalParentName Internal Parent name to filter by
 * @param {string} config.data.iata IATA code to filter by
 * @param {string} config.data.iso2 Country ISO code to filter by
 * @param {string} config.data.nameSearch Airport name to search by.
 * @param {string} config.data.internalParentNameSearch Internal Parent name to search by
 * @param {string} config.data.iataSearch IATA code to search by
 *
 * @returns {Promise}
 *
 * */
function getAirportListSearches(config) {

  const {
    params,
    data,
    signal
  } = config;

  const {sort , ...rest} = params;

  const url = `${apiBase}/airports/searches`;

  return waRequest.requestAuthenticated({
    url,
    method: 'post',
    params: {sort: (!sort ? DEFAULT_AIRPORT_CODE_SORT : sort), ...rest},
    data,
    signal
  })
}

function airportSearchList({params:{locale, page, size, sort}, data: {quickSearch}, signal}) {

  const data = {
    nameSearch: quickSearch,
    iataSearch: quickSearch,
    internalParentNameSearch: quickSearch,
  };

  return getAirportListSearches({params: {locale, page, size, sort}, data, signal});

}

function railwaySearchList({params: {locale, page, size, sort}, data: {quickSearch}, signal}) {
  const url = `${apiBase}/rail-stations/quick-searches`;

  return cancelableRailwayListSingleton.requestAuthenticated({
    url,
    method: 'post',
    params: {
      locale,
      page,
      size,
      sort
    },
    data: {
      codeSearch: quickSearch,
      internalParentNameSearch: quickSearch,
      nameSearch: quickSearch
    },
    signal
  })
}

/**
 * Initiate authenticated GET ${baseApi}/rail-stations/quick-searches
 *
 * @param {Object} params Contains nameSearch, codeSearch, page, sort, size, etc.
 * @returns {Promise}
 */
function getRailwaysList(params) {
  const url = `${apiBase}/rail-stations/quick-searches`;

  return cancelableRailwayListSingleton.requestAuthenticated({
    url: url,
    method: 'post',
    data: params
  });
}

function getAirportDetails({pathParams: {id}, signal}) {
  const url = `${apiBase}/airports/${id}`;

  return waRequest.requestAuthenticated({
    url,
    method: 'get',
    signal
  })
}

export default {
  getLocations,
  getLocationDetails,
  postAirportNameAndIataSearch,
  airportSearchList,
  railwaySearchList,
  getRailwaysList,
  getAirportDetails,
  getLocationsByNameConfigPattern,
  getAirportListSearches
};

